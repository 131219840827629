import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import DevCentral from "./pages/DevCentral/DevCentral";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AudioProvider } from "./components/AudioProvider"; //when we navigate to different pages the audio should keep playing

const router = createBrowserRouter([
  {
    path: "/",
    element: <DevCentral />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AudioProvider>
      <RouterProvider router={router} />
    </AudioProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
