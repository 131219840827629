import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Connect from "./Connect"; // Import the ConnectForm component

const navigation = [
  { name: "About", id: "aboutsection" },
  { name: "GitGuide", id: "gitguide" },
  { name: "DevTools", id: "devtools" },
  { name: "Connect", id: "connect" }, // New Connect link
];

function Footer() {
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  // Open modal when Connect is clicked
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleNavigationClick = (id) => {
    if (pathname === "/") {
      // If we're already on the home page, scroll to the section
      const element = document.getElementById(id);
      if (element) {
        // Adjust the scroll position by a fixed amount (e.g., -50px)
        const offset = -200; // Customize this value to suit your needs
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition + offset,
          behavior: "smooth",
        });
      }
    } else {
      // If we're on a different page, scroll to the top of the page first
      window.scrollTo({ top: 0, behavior: "smooth" });
      // Optionally, you can also handle redirects here if needed
    }
  };

  return (
    <footer className="bg-white text-black p-4 pt-20 px-4 sm:px-6 md:px-8 lg:px-12">
      <div className="flex flex-col sm:flex-row justify-between items-center gap-6 lg:gap-12">
        {/* Navigation Links */}
        <div className="flex flex-wrap justify-center lg:justify-end gap-4 pr-0 lg:pr-8">
          {navigation.map((item) =>
            item.name === "Connect" ? (
              <button
                key={item.name}
                onClick={openModal} // Open modal on click of Connect
                className="text-base sm:text-lg font-medium link_hover_effect"
              >
                {item.name}
              </button>
            ) : (
              <button
                key={item.name}
                onClick={() => handleNavigationClick(item.id)}
                className="text-base sm:text-lg font-medium link_hover_effect"
              >
                {item.name}
              </button>
            )
          )}
        </div>

        {/* Copyright Section */}
        <div className="text-center lg:text-start text-gray-400 text-sm sm:pr-12">
          Copyright © {new Date().getFullYear()} DevCentral
        </div>
      </div>

      {/* Modal Popup */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="w-full sm:w-96">
            <Connect closeModal={closeModal} /> {/* Use the ConnectForm here */}
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
