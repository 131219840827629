import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import navbar_logo from "../assets/navbar_logo.png";

const navigation = [
  { name: "About", id: "aboutsection" },
  { name: "GitGuide", id: "gitguide" },
  { name: "DevTools", id: "devtools" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let lastScrollTop = window.scrollY;

    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setVisible(false);
      } else {
        // Scrolling up
        setVisible(true);
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // Prevent negative scroll
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigationClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav
      className={`bg-white z-10 sticky top-0 left-0 right-0 transition-transform duration-300 -1 ${
        visible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 pb-4 lg:px-8">
        <div className="flex flex-col h-34 items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <img src={navbar_logo} alt="Dev Central" className="w-auto h-32" />
          </Link>

          {/* Navigation Links */}
          <div className="flex sm:flex-row flex-col gap-4">
            <div className="flex space-x-6">
              {navigation.map((item) => (
                <button
                  key={item.name}
                  onClick={() => handleNavigationClick(item.id)}
                  className={classNames(
                    "text-black link_hover_effect text-lg sm:text-xl font-medium"
                  )}
                >
                  {item.name}
                </button>
              ))}
            </div>
            <a
              href="https://prabhavdev.me/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black link_hover_effect text-lg sm:text-xl font-medium text-center"
            >
              prabhavdev.me
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
