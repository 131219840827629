import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function ConnectForm({ closeModal }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    address: "",
    message: "",
  });

  const handleChange = (e) => {
    if (e.target) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        number: e, // `e` is the value directly from PhoneInput
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "0d4d43d2-3744-475c-956b-7c6b6ed73545", // Replace with your own access key
          ...formData,
        }),
      });

      if (response.ok) {
        setIsSuccess(true);
        setFormData({
          name: "",
          email: "",
          number: "",
          address: "",
          message: "",
        });
      } else {
        console.error("Form submission error");
      }
    } catch (error) {
      console.error("Form submission error", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-1/2 md:w-1/3"
      style={{
        position: "fixed",

        too: "50%",
        left: "50%", // Center horizontally
        transform: "translate(-50%, -50%)", // Adjust for perfect centering
        width: "90%", // Adjust width as needed (responsive)
        maxWidth: "600px", // Maximum width for larger screens
        zIndex: 20,
      }}
    >
      <h2 className="text-2xl font-semibold mb-4">Connect with Us</h2>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <input
          type="hidden"
          name="access_key"
          value="0d4d43d2-3744-475c-956b-7c6b6ed73545"
        />

        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full">
            <input
              type="text"
              name="name"
              className="w-full bg-white border rounded-md py-2 px-3 text-gray-700"
              placeholder="Name*"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="w-full">
            <input
              type="email"
              name="email"
              className="w-full bg-white border rounded-md py-2 px-3 text-gray-700"
              placeholder="Email*"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full sm:w-1/2">
            <PhoneInput
              international
              defaultCountry="IN"
              name="number"
              className="w-full bg-white border rounded-md py-2 px-3 text-gray-700"
              placeholder="Phone number*"
              value={formData.number}
              onChange={handleChange}
              required
            />
          </div>

          <div className="w-full sm:w-1/2">
            <input
              type="text"
              name="address"
              className="w-full bg-white border rounded-md py-2 px-3 text-gray-700"
              placeholder="Enter your address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="w-full">
          <textarea
            name="message"
            className="w-full bg-gray-50 border rounded-md py-2 px-3 text-gray-700 h-32 resize-none"
            placeholder="Your message here*"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={closeModal}
            className="hover:bg-black bg-white text-black hover:text-white font-normal text-md py-2 px-3 border border-gray-300 rounded-xl transition duration-300 ease-in-out"
          >
            Cancel
          </button>

          <button
            type="submit"
            className="hover:bg-black bg-white text-black hover:text-white font-normal text-md py-2 px-3 border border-gray-300 rounded-xl transition duration-300 ease-in-out"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "Submit"}
          </button>
        </div>
      </form>

      {isSuccess && (
        <div className="p-4 mt-4 text-green-600 border border-green-300 rounded-md bg-green-50">
          Message sent successfully!
        </div>
      )}
    </div>
  );
}

export default ConnectForm;
