// React context provider that manages audio playback functionality, allowing any component within its scope to control the audio
//no need to pass toggleAudio down through multiple layers of components. By using context, any component within AudioProvider can simply use useAudio to access audio control.
import React, { createContext, useContext, useRef, useState } from "react"; // set up the context, manage audio playback, and track state.
import audioFile from "../assets/voice_devcentral.mp3";

const AudioContext = createContext(); //allowing components to access the audio’s state and functions through context rather than passing props.

export const useAudio = () => useContext(AudioContext);
//Any component can use this useAudio hook to control the audio without directly interacting with AudioContext. useAudio is a custom hook that simply returns the AudioContext data using useContext. This way, you don’t need to call useContext(AudioContext) directly every time.

// useContext(AudioContext) is called, it looks for the nearest AudioContext.Provider above it in the component tree and gives the component access to the values inside the provider (i.e., isPlaying and toggleAudio).
// export const useAudio = () => useContext(AudioContext);

export const AudioProvider = ({ children }) => {
  //AudioProvider wraps its children, making the audio controls available to all components within its scope.
  const audioRef = useRef(new Audio(audioFile)); //audioRef uses useRef to hold an instance of the Audio object without re-creating it on every render, ensuring consistent audio playback control.
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause(); //If isPlaying is true, on clicking icon the audio pauses.
    } else {
      audioRef.current.play(); //If isPlaying is false, on clicking icon the audio pauses.
    }
    setIsPlaying(!isPlaying); //setIsPlaying(!isPlaying) updates isPlaying based on the current state.
  };

  return (
    //AudioContext.Provider provides isPlaying and toggleAudio as context values. Any component within AudioProvider can access these values using the useAudio hook.
    <AudioContext.Provider value={{ isPlaying, toggleAudio }}>
      {children}
    </AudioContext.Provider>
  );
};
